
import {Component, Mixins, Prop } from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import AbSubFormModel from '@/components/Models/AbSubFormModel';
import DynamicSection from '@/components/Forms/DynamicSection.vue';
import {uuid} from 'vue-uuid';
import FormMixin from '@/components/Mixin/FormMixin';
import AbModel from "@/components/Models/AbModel";

@Component({
  components: {DynamicSection}
})
// eslint-disable-next-line
// @ts-ignore: Unreachable code error
export default class DynamicSubForm extends Mixins(FormMixin, DynamicForm) {
  @Prop(Object) subFormFieldData;
  @Prop(Object) subFormFieldInfo?: any = null;
  @Prop(Object) parentFormData?: any = null;

  model: AbSubFormModel = new AbSubFormModel();

  async loadModelInfo() {
    const data = await this.model.load('');
    return data;
  }

  getIsFormLocked() {
    let lockedForm = false;
    if (this.parentFormData && this.parentFormData.locked) {
      lockedForm = true;
    }
    return lockedForm;
  }

  saveToModel(dataToSave) {
    const model = new AbModel();
    if (!dataToSave.ID) {
      dataToSave.ID = uuid.v1();
      model.setCreated(dataToSave);
    } else {
      model.setUpdated(dataToSave);
    }
    const data = {dataToSave, noCloseModalForm: this.formData.noCloseModalForm || this.formData.autoSave};
    this.formData.noCloseModalForm = false
    this.formData.autoSave = false
    this.$emit("saveSubformData", data);
  }

  async getModelData() {
    return this.subFormFieldData;
  }

  setUnsavedData() {
    this.$emit('setUnsavedData')
  }

}
